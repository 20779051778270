<template>
  <!-- Quest title -->
  <div>
    <v-text-field
        v-model="quest.title"
        dense
        outlined
        :label="cLabel.title"
        :rules="cRules.title"
        @input="emitInput"
    />
    <eins-image-input
        aspect-ratio="1"
        :title="cLabel.titlePicture.title"
        :cancel-label="cLabel.titlePicture.cancel"
        :send-label="cLabel.titlePicture.submit"
        :rules="cRules.titlePicture"
        @select="onSelectFile"
    />
    <eins-input-textarea
        v-model="quest.description"
        :label="cLabel.description"
        :rules="cRules.description"
        class="mt-3"
        dense
        outlined
        @input="emitInput"
    />
    <eins-input-time-value
        v-model="quest.value"
        :label="cLabel.value"
        :max="1440"
        :min="60"
        :cap-with-user-balance="cIsPrivateQuest"
        star-mode
        @input="emitInput"
    />
    <eins-input-group-scope-dialog
        v-model="quest.player_group_id"
        :current-value-label="cLabel.scope.currentValueLabel"
        :dialog-title="cLabel.scope.dialogTitle"
        :void-option="cLabel.scope.voidOption"
        :list-caption="cLabel.scope.listCaption"
        :submit-label="cLabel.scope.submit"
        @input="emitInput"
    />
    <eins-input-location
        v-model="questLocation"
        :dialog-title-address="cLabel.location.addressTitle"
        :submit-label-address="cLabel.location.addressSubmit"
        :rules="cRules.location"
        @input="emitInput"
    />
  </div>
</template>

<script>
import {
  questTitleValid,
  questPictureValid,
  questDescriptionMinLengthValid,
  questDescriptionMaxLengthValid
} from '@/utils/inputRules'
import EinsImageInput from '@/components/input/EinsImageInput'
import EinsInputTextarea from '@/components/input/EinsInputTextarea'
import EinsInputTimeValue from '@/components/input/EinsInputTimeValue'
import {
  QuestType
} from '@/constants'
import EinsInputLocation from '@/components/input/location/EinsInputLocation'
import {
  isValidGeolocation
} from '@/utils/geo'
import EinsInputGroupScopeDialog from '@/components/input/EinsInputGroupScopeDialog'

const getEmptyItem = (store) => ({
  title: '',
  picture: null,
  description: '',
  templateName: '',
  value: 60,
  player_group_id: null,
  public: false,
  type: '',
  hide_when_finished: null,
  latitude: store.getters['players/current/lat'],
  longitude: store.getters['players/current/long'],
  street: '',
  house_number: '',
  postal_code: '',
  city: '',
  country: 'Deutschland'
})

export default {
  name: 'EinsQuestCreateForm',
  components: {
    EinsInputGroupScopeDialog,
    EinsInputLocation,
    EinsInputTimeValue,
    EinsInputTextarea,
    EinsImageInput
  },
  props: {
    value: {
      type: Object,
      default: getEmptyItem
    },
    questType: {
      type: String,
      default: 'private'
    }
  },
  data () {
    return {
      quest: getEmptyItem(this.$store),
      questNameOverlay: false,
      alert: {
        cancel: false
      },
      rules: {
        questTitleValid,
        questPictureValid,
        questDescriptionMinLengthValid,
        questDescriptionMaxLengthValid
      }
    }
  },
  computed: {
    cLabel () {
      const t = path => this.$t(`quest.create.${path}`)

      return {
        title: t('title.label'),
        description: t('description.label'),
        value: t('value.label'),
        hideWhenFinished: {
          label: t('hideWhenFinished.label'),
          hint: t('hideWhenFinished.hint')
        },
        location: {
          addressTitle: t('location.addressTitle'),
          addressSubmit: t('location.addressSubmit')
        },
        titlePicture: {
          title: t('titlePicture.title'),
          cancel: t('titlePicture.cancel'),
          submit: t('titlePicture.submit')
        },
        scope: {
          currentValueLabel: t('scope.currentValueLabel'),
          dialogTitle: t('scope.dialogTitle'),
          listCaption: t('scope.listCaption'),
          voidOption: { label: t('scope.voidOption') },
          submit: t('scope.submit')
        }
      }
    },
    cRules () {
      return {
        title: [
          value => !!value || this.$t('quest.create.title.required'),
          value => !value || value.length >= 3 || this.$t('quest.create.title.min', [3])
        ],
        description: [
          value => !!value || this.$t('quest.create.description.required'),
          value => !value ||
            value.split(/ |\n/g).filter(part => !!part).length >= 5 ||
            this.$t('quest.create.description.min', [5])
        ],
        titlePicture: [
          value => !!value || this.$t('quest.create.titlePicture.required')
        ],
        location: [
          value => {
            const isValidPosition = isValidGeolocation({
              lat: value.latitude,
              lon: value.longitude
            })
            const hasAddress = value &&
              value.street !== '' &&
              value.house_number !== '' &&
              value.city !== '' &&
              value.postal_code !== '' &&
              value.country !== ''

            return isValidPosition || hasAddress || this.$t('quest.create.location.required')
          }
        ]
      }
    },
    cIsPrivateQuest () {
      return this.quest.type === QuestType.PRIVATE
    },
    cCanHideQuest () {
      return this.$store
        .getters['players/current/groups/item'](this.quest.player_group_id)
        ?.group_type.can_hide_quests ?? false
    },
    cBalanceAvailable () {
      if (this.questType === 'private') {
        return this.$store.getters['players/current/timeAccount']?.balance_available
      } else {
        return null
      }
    },
    questLocation: {
      get () {
        return {
          latitude: this.quest.latitude,
          longitude: this.quest.longitude,
          street: this.quest.street,
          house_number: this.quest.house_number,
          postal_code: this.quest.postal_code,
          city: this.quest.city,
          country: this.quest.country
        }
      },
      set (val) {
        this.quest.latitude = val.latitude
        this.quest.longitude = val.longitude
        this.quest.street = val.street
        this.quest.house_number = val.house_number
        this.quest.postal_code = val.postal_code
        this.quest.city = val.city
        this.quest.country = val.country
      }
    }
  },
  watch: {
    value: 'setValue',
    cCanHideQuest (value) {
      if (!value) {
        this.quest.hide_when_finished = null
      }
    }
  },
  created () {
    this.setValue()
  },
  methods: {
    onSelectFile (file) {
      this.quest.picture = file
      this.emitInput()
    },
    setValue () {
      const emptyItem = getEmptyItem(this.$store)
      emptyItem.type = this.questType
      this.$set(this.$data, 'quest', {
        ...emptyItem,
        ...this.value
      })
    },
    emitInput () {
      this.$emit('input', {
        ...this.quest
      })
    },
    onClickSubmit () {
      this.emitInput()
      this.$emit('click:submit')
    },
    onChangePlayerGroupId () {
      if (this.quest.player_group_id === null) {
        this.quest.public = false
      }
      this.emitInput()
    }
  }
}
</script>

<style scoped>
</style>
